export const config = {
    RPC_URL: 'https://chihuahua-rpc.publicnode.com',
    REST_URL: 'https://chihuahua-rest.publicnode.com',
    EXPLORER_URL: 'https://www.mintscan.io/chihuahua',
    STAKING_URL: 'https://chihuahua.omniflix.co/stake',
    WALLET_URL: 'https://chihuahua.omniflix.co',
    COIN_IMAGE_URL: 'https://raw.githubusercontent.com/ChihuahuaChain/resources/main/logo/logo_transparent_notext.png',
    NETWORK_NAME: 'Chihuahua',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'chihuahua-1',
    CHAIN_NAME: 'Chihuahua',
    COIN_DENOM: 'HUAHUA',
    COIN_MINIMAL_DENOM: 'uhuahua',
    COIN_DECIMALS: 6,
    PREFIX: 'chihuahua',
    COIN_TYPE: 118,
    COSMOSTAION: 'chihuahua',
    COINGECKO_ID: 'chihuahua-token',
    GAS_PRICE_STEP_LOW: 1,
    GAS_PRICE_STEP_AVERAGE: 5,
    GAS_PRICE_STEP_HIGH: 10,
    FEATURES: ['cosmwasm', 'ibc-transfer', 'ibc-go'],
};
